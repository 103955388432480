import { AxiosError } from 'axios'
import dayjs from 'dayjs'
import isoWeek from 'dayjs/plugin/isoWeek'
import { action, computed, makeAutoObservable } from 'mobx'

import { CURRENT_ORDER_KEYS, STORE_KEYS } from '@constants'
import {
  extractAPIFieldErrors,
  extractOrderSummaryExtrasFormValues,
  isEmptyObject,
  transformOrderSummaryExtras,
} from '@helpers'
import { Store } from '@stores'
import {
  IExtrasProduct,
  IOrderDetails,
  IOrderDetailsOverviewExtraDayProduct,
  IOrderDetailsOverviewSoupOrSalad,
  IOrderLunchersPayload,
  IOrderPreferencePayload,
  ISoupSaladMenuProduct,
  ISoupsSaladsResponse,
} from '@typings'

dayjs.extend(isoWeek)

export class CurrentOrderStore {
  store: Store
  loadingInitial: boolean
  loadingStep: boolean
  loadingSubmitting: boolean
  currentStep: number
  currentOrder: IOrderDetails | null
  availableMenus: ISoupsSaladsResponse | null
  formValues: any
  currentOrderId: IOrderDetails['id'] | null
  currentOrderStatus: IOrderDetails['status'] | null

  constructor(store: Store) {
    makeAutoObservable(this)
    this.store = store
    this.loadingInitial = false
    this.loadingStep = false
    this.loadingSubmitting = false
    this.currentStep = 1
    this.currentOrder = null
    this.formValues = {}
    this.availableMenus = null
    this.currentOrderId = null
    this.currentOrderStatus = null
  }

  @computed
  get watchLoadingInitial(): CurrentOrderStore['loadingInitial'] {
    return this.loadingInitial
  }

  @computed
  get watchLoadingStep(): CurrentOrderStore['loadingStep'] {
    return this.loadingStep
  }

  @computed
  get watchLoadingSubmitting(): CurrentOrderStore['loadingSubmitting'] {
    return this.loadingSubmitting
  }

  @computed
  get watchAvailableSalads(): ISoupsSaladsResponse['salad'] {
    return this.availableMenus?.salad
  }

  @computed
  get watchAvailableSoups(): ISoupsSaladsResponse['soup'] {
    return this.availableMenus?.soup
  }

  @computed
  get watchCurrentOrderSoupDetails():
    | IOrderDetailsOverviewSoupOrSalad[]
    | undefined {
    return this.currentOrder?.overview?.soups
  }

  @computed
  get watchCurrentOrderSaladDetails():
    | IOrderDetailsOverviewSoupOrSalad[]
    | undefined {
    return this.currentOrder?.overview?.salads
  }

  @computed
  get watchFormValues(): any {
    if (isEmptyObject(this.formValues)) return null
    return this.formValues
  }

  @action
  setWeekIdentifier = (weekIdentifier: string) => {
    this.formValues.week = weekIdentifier
  }

  @action
  setCurrentOrder = (order: IOrderDetails) => {
    this.currentOrder = order
    this.currentOrderId = order.id
    this.currentOrderStatus = order.status
  }

  @action
  fetchPreferenceStepInitialValues = async () => {
    return this.formValues
  }

  @action
  updateFormValues = (payload: any) => {
    for (const [key, value] of Object.entries(payload)) {
      this.formValues[key] = value
    }
  }

  @action
  submitLuncherCount = async (
    payload: IOrderLunchersPayload,
    isOrderEdit = false,
  ) => {
    this.updateFormValues(payload)

    try {
      this.loadingSubmitting = true
      if (isOrderEdit && this.currentOrderId) {
        await this.store.api.specifications.edit_validate_order_luncher_count(
          this.currentOrderId,
          payload,
        )
      } else {
        await this.store.api.specifications.validate_order_luncher_count(
          payload,
        )
      }
      return Promise.resolve(true) // Precognition API does not return any values
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  submitPreferences = async (
    payload: IOrderPreferencePayload,
    isOrderEdit = false,
  ) => {
    this.updateFormValues(payload)

    try {
      this.loadingSubmitting = true
      if (isOrderEdit && this.currentOrderId) {
        await this.store.api.specifications.edit_validate_order_preferences(
          this.currentOrderId,
          payload,
        )
      } else {
        await this.store.api.specifications.validate_order_preferences(payload)
      }
      return Promise.resolve(true) // Precognition API does not return any values
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  createOrderBody = () => {
    return {
      week: this.formValues.week,
      monday: this.formValues.monday,
      tuesday: this.formValues.tuesday,
      wednesday: this.formValues.wednesday,
      thursday: this.formValues.thursday,
      friday: this.formValues.friday,
      soup: this.formValues.soup,
      salad: this.formValues.salad,
      comment: this.formValues.comment,
    }
  }

  @action
  initFormValues = (order: IOrderDetails) => {
    const formValues = {
      week: order.week,
      monday: order.overview?.lunchers.find(item => item.day === 'monday')
        ?.count,
      tuesday: order.overview?.lunchers.find(item => item.day === 'tuesday')
        ?.count,
      wednesday: order.overview?.lunchers.find(item => item.day === 'wednesday')
        ?.count,
      thursday: order.overview?.lunchers.find(item => item.day === 'thursday')
        ?.count,
      friday: order.overview?.lunchers.find(item => item.day === 'friday')
        ?.count,
      soup: order.overview?.soups?.map(item => item.id),
      salad: order.overview?.salads?.map(item => item.id),
      comment: order.overview?.comment,
    }

    const extractExtrasFormValues = extractOrderSummaryExtrasFormValues(
      order.overview,
    )

    const formBody = { ...formValues, ...extractExtrasFormValues }
    this.formValues = formBody
  }

  @action
  createOrder = async (body: any, availableExtras: IExtrasProduct[]) => {
    try {
      this.loadingSubmitting = true

      const extrasPerDay = transformOrderSummaryExtras(body, availableExtras)
      const renderExtras = !extrasPerDay?.length
        ? null
        : extrasPerDay.map(extra => ({
            day: extra.day,
            product_id: extra.product?.id,
            quantity: extra.qty,
          }))

      this.updateFormValues({ extras: renderExtras })

      const orderBody = {
        ...this.createOrderBody(),
        extras: renderExtras,
      }

      const newOrder =
        await this.store.api.specifications.create_order(orderBody)
      if (newOrder) {
        return Promise.resolve(newOrder)
      }
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  editOrder = async (
    orderId: IOrderDetails['id'],
    body: any,
    availableExtras: IExtrasProduct[],
  ) => {
    try {
      this.loadingSubmitting = true

      const extrasPerDay = transformOrderSummaryExtras(body, availableExtras)
      const renderExtras = !extrasPerDay?.length
        ? null
        : extrasPerDay.map(extra => ({
            day: extra.day,
            product_id: extra.product?.id,
            quantity: extra.qty,
          }))

      this.updateFormValues({ extras: renderExtras })

      const orderBody = {
        ...this.createOrderBody(),
        extras: renderExtras,
      }

      const newOrder = await this.store.api.specifications.edit_order(
        orderId,
        orderBody,
      )
      if (newOrder) {
        return Promise.resolve(newOrder)
      }
    } catch (error) {
      const err = extractAPIFieldErrors(error as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingSubmitting = false
    }
  }

  @action
  fetchOrderById = async (id: number, updateFormValues = false) => {
    this.loadingInitial = true
    try {
      const order = await this.store.api.specifications.get_single(id)
      if (order) {
        this.setCurrentOrder(order)

        if (updateFormValues) {
          this.initFormValues(order)
        }
      }
      return Promise.resolve(order)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingInitial = false
    }
  }

  @action
  fetchOrderByIdentifier = async (identifier: string) => {
    this.loadingInitial = true
    try {
      const order =
        await this.store.api.specifications.get_single_by_identifier(identifier)
      if (order) this.setCurrentOrder(order)
      return Promise.resolve(order)
    } catch (e) {
      const err = extractAPIFieldErrors(e as Error | AxiosError)
      return Promise.reject(err)
    } finally {
      this.loadingInitial = false
    }
  }

  @action
  fetchAvailableMenus = async (identifier: string) => {
    this.loadingStep = true
    try {
      const menus = await this.store.api.products.fetchMenus(identifier)
      if (menus) this.availableMenus = menus
      return Promise.resolve(menus)
    } catch (error) {
      return Promise.reject(error)
    } finally {
      this.loadingStep = false
    }
  }

  @action
  findCurrentOrderExtraById = (id: IExtrasProduct['id']) => {
    if (!this.currentOrder) return null
    const extras = this.currentOrder.overview?.extras?.days
    if (!extras?.length) return null
    const extrasList: IOrderDetailsOverviewExtraDayProduct[] = []
    extras.forEach(extra => {
      extra.products.forEach(prod => extrasList.push(prod))
    })
    return extrasList.find(prod => prod.id === id)
  }

  @action
  findCurrentOrderSaladById = (id: ISoupSaladMenuProduct['id']) => {
    if (!this.currentOrder) return null
    const soups = this.currentOrder.overview?.salads
    if (!soups?.length) return null
    return soups.find(soup => soup.id === id)
  }

  @action
  findCurrentOrderSoupById = (id: ISoupSaladMenuProduct['id']) => {
    if (!this.currentOrder) return null
    const soups = this.currentOrder.overview?.soups
    if (!soups?.length) return null
    return soups.find(soup => soup.id === id)
  }

  @action
  reset = () => {
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'loadingInitial', false)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'loadingStep', false)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'loadingSubmitting', false)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'currentStep', 1)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'currentOrder', null)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'currentOrderId', null)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'currentOrderStatus', null)
    this.store.set(STORE_KEYS.CURRENT_ORDER, 'formValues', {})
  }
}
